@import url('https://fonts.googleapis.com/css2?family=Pinyon+Script&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Great+Vibes&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Herr+Von+Muellerhoff&display=swap');



@tailwind base;
@tailwind components;
@tailwind utilities;

.pinyon-script-regular {
  font-family: "Pinyon Script", cursive;
  font-weight: 400;
  font-style: normal;
  font-size: 30px;
}

.great-vibes-regular {
  font-family: "Great Vibes", cursive;
  font-weight: 400;
  font-style: normal;
  font-size: 30px;
}

.herr-von-muellerhoff-regular {
  font-family: "Herr Von Muellerhoff", cursive;
  font-weight: 400;
  font-style: normal;
  font-size: 30px;
}

.drop-down{
 font-size: 18px !important;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.textarea1 {
  resize: none;
}

@media screen and (min-width: 576px) {
  .addwidth {
    max-width: 600px !important;
  }
}

.modal-header-padding {
  margin-right: 1.5rem !important;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  background-color: #0ae;

  background-image: -webkit-gradient(
    linear,
    0 0,
    0 100%,
    color-stop(0.5, rgba(255, 255, 255, 0.2)),
    color-stop(0.5, transparent),
    to(transparent)
  );
}

.btn-blue {
  background: #0a59ca !important;
  color: white !important;
  border-radius: 1rem !important ;
  padding-right: 3rem !important;
  padding-left: 3rem !important;
  font-size: 1.2rem !important;
  font-weight: 500 !important;
}

.btn-white {
  background: white !important;
  border: 1px solid black !important;
  color: black !important;
  border-radius: 1rem !important ;
  padding-right: 3rem !important;
  padding-left: 3rem !important;
  font-size: 1.2rem !important;
  font-weight: 500 !important;
}

@media screen and (max-width: 768px) {
  .btn-white {
    background-color: lightgreen;
    padding-right: 0.7rem !important;
    padding-left: 0.7rem !important;
    font-size: 1rem !important;
  }
  .btn-blue {
    background-color: lightgreen;
    padding-right: 0.7rem !important;
    padding-left: 0.7rem !important;
    font-size: 1rem !important;
  }
}

@media screen and (max-width: 560px) {
  .custom-toggle-btn {
    font-size: 0.8rem !important;
    font-weight: 500 !important;
    padding: 0.5rem !important;
  }
}

@media (min-width: 768px) {
  .submit-hidden {
    display: none !important;
  }
}

@media (max-width: 768px) {
  .sm-hidden {
    display: none !important;
  }
}

@media (min-width: 768px) {
  .sm-hidden {
    display: block !important;
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield !important;
}

.react-confirm-alert-overlay {
  z-index: 20000 !important;
}

.css-t3ipsp-control:hover {
  border-color: #c2dbfe !important;
  border-width: 2px !important;
}

.css-t3ipsp-control {
  box-shadow: 0 0 0 1px #c2dbfe !important ;
  border-color: #c2dbfe !important;
  border-width: 2px !important;
}

/* .rbc-timeslot-group:hover {
  border: #0a59ca solid 2px !important;
}

.rbc-time-slot:hover {
  border: #0a59ca solid 2px !important;
} */

.rbc-day-slot {
  cursor: pointer;
}

.rbc-row-content {
  pointer-events: none;
}

.rbc-day-slot .rbc-time-slot {
  border-top: 1px solid #e5e0ff !important;
}

.rbc-timeslot-group {
  min-height: 100px !important;
}

.rbc-time-view .rbc-allday-cell {
  pointer-events: none;
}

.client .rbc-event {
  background-color: black;
  color: white;
  left: 0 !important;
  width: 100% !important;
}
.client .rbc-current-time-indicator {
  background-color: black;
}

.client .rbc-today {
  background-color: #2e2e2e14;
}

.client .rbc-calendar {
  min-height: 100vh;
}

.sch-calender .rbc-event {
  left: 0 !important;
  width: 100% !important;
}

.rbc-events-container {
  margin-right: 0 !important;
}

.rbc-event-content {
  text-wrap: nowrap;
  font-size: small;
  margin-top: 2px;
}

.tabs{
  padding: 9px 15px;
}

.selected-tab{
  border: 2px solid #E5E7EB;
  box-sizing: border-box;
  border-bottom-color: #fff;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  background-color: #fff;
}

.required-checkbox[type=checkbox]{
accent-color: black !important;
}

.non-required-checkbox[type=checkbox]{
  accent-color: #3a95f0 !important;
  }

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
}

.fonts{
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}

.ReactModal__Overlay--after-open{
  z-index: 999;
  background-color: #f8f9fac9;
}

.ReactModal__Content{
  transform: translate(-50%, 0%) !important;
  top: 8% !important;
  --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
    --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.top-model-table{
  max-height: 500px;
  overflow-y:auto;
}

.create-btn{
  background-color: #22d3ee !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  padding: 0.5rem !important;

}
.del-btn{
  background-color: #f44336 !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  padding: 0.5rem !important;
}

.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input{
  padding: 7.5px 14px;
}

.rbc-day-slot .rbc-event{
  width: 100% !important;
  left: 0px !important;
}

.rbc-calendar{
  padding: 30px 8px !important;
}



@media screen and (min-width: 320px) {
  .rbc-header{
    height: 49px;
    white-space: normal !important;
  }

  .rbc-time-gutter {
    width: 50px;
    white-space: normal !important;
  }
  .rbc-time-slot{
    text-align: center;
  }

  .rbc-time-header-gutter{
    width: 50px !important;
    min-height: 50px !important;
    max-width: 50px !important;
  }

  .rbc-label {
    padding: 0 !important;
    font-size: 15px !important;
}

.rbc-event-content{
  display: none;
}

.rbc-event{
  padding: 2px 0px !important;
}

.rbc-event-label{
  font-size: 8px !important;
  white-space: normal !important;
}

.react-confirm-alert-button-group > .btn{
  font-size: 14px !important;
  padding: 5px 8px !important;
}
.react-confirm-alert-body{
width: 290px !important;
}

.react-confirm-alert-body > h1{
  font-size: 21px;
}

}

@media screen and (min-width: 768px) {
  .rbc-header{
    height: auto;
    white-space: nowrap !important;
  }

.rbc-time-gutter {
    width: auto !important;
    white-space: nowrap !important;
  }
  .rbc-time-slot{
    text-align: center;
  }

  .rbc-time-header-gutter{
    width: 78.02px !important;
    min-height: 78.02px !important;
    max-width: 78.02px !important;
  }
  .rbc-label {
    padding: 0 5px !important;
    font-size: 16px !important;
}

.rbc-event-content{
  display: block;
  font-size: 9px !important;
  white-space: wrap !important;
}

.rbc-event-label{
  font-size: 10px !important;
}

.react-confirm-alert-button-group > .btn{
  font-size: 16px !important;
  padding: 8px 12px !important;
}
.react-confirm-alert-body{
width: 400px !important;
}

.react-confirm-alert-body > h1{
  font-size: 32px;
}
}

@media screen and (min-width: 1024px) {
  .rbc-event-content{
    font-size: 10px !important;
  }

  .rbc-event-label{
    font-size: 10px;
  }
}

@media screen and (min-width: 1440px) {
  .rbc-event-content{
    font-size: 13px !important;
  }

  .rbc-event-label{
    font-size: 13px !important;
  }
}