.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.countrySelectContainer .react-tel-input .form-control{
  width: 100% !important;
}
.react-datepicker-wrapper{
  width: 86% !important;
}
.report-section .nav-link{
color: #696977 !important;
}
.popover{
  max-width: fit-content;
  display: flex;
  justify-content: start;
  gap: 20px;
  align-items: center;
}
.date-range-highlight {
  background-color: #d1ecf1; 
  border-radius: 0;
  color: #333;
}

.date-range-start {
  background-color: #17a2b8; 
  color: white;
  border-radius: 50% 0 0 50%;
}

.date-range-end {
  background-color: #17a2b8;
  color: white;
  border-radius: 0 50% 50% 0;
}
.collapseContainer .collapse{
visibility: visible;
}
/* Highlight the start date */
.start-date {
  background-color: #1ea7fd !important;
  color: #fff !important;
  border-radius: 50%;
}

/* Highlight the end date */
.end-date {
  background-color: #1ea7fd !important;
  color: #fff !important;
  border-radius: 50%;
}

/* Highlight dates in range */
.in-range {
  background-color: #c2e3f4 !important; /* Light blue */
  color: #000 !important;
  border-radius: 50%;
}
